import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  ImageField,
  FileInput,
  FileField,
  BooleanInput,
  BooleanField,
  ReferenceField,
} from "react-admin";

export const SupplyList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="supply type" source="supply_type_id" reference="supply_typesAdmin">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="price" />
      <ImageField source="icon" />
      <ImageField source="image" />
      <BooleanField source="provided_by_school" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SupplyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="supply_type_id" reference="supply_typesAdmin">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="price" />
      <FileInput source="icon">
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput source="image">
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="provided_by_school" />
    </SimpleForm>
  </Edit>
);

export const SupplyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="supply_type_id" reference="supply_typesAdmin">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="price" />
      <FileInput source="icon">
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput source="image">
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="provided_by_school" />
    </SimpleForm>
  </Create>
);
