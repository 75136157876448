import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileField,
  FileInput,
  useNotify,
  useRefresh,
  useRedirect,
  ImageField,
} from 'react-admin';
import environment from '../config/environment';

const classDefaultValues = {
  email: null,
};

export const SchoolList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="address" />
      <TextField source="phone" />
      <TextField source="email" />
      <ImageField source="icon" title="Icon" />
      <ImageField source="image" title="Image" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);



const handleSubmit = async (
  values,
  notify,
  redirect,
  refresh,
  apiUrl,
  method,
) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    if (values[key] instanceof File) {
      formData.append(key, values[key], values[key].name);
    } else if (values[key]) {
      formData.append(key, values[key]);
    }
  });

  try {
    const response = await fetch(apiUrl, {
      method: method,
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      notify(responseData.message || `HTTP error! status: ${response.status}`, {
        type: 'warning',
      });
    } else {
      notify('School saved successfully', { type: 'info' });
      redirect('list', '/schoolsAdmin');
      refresh();
    }
  } catch (error) {
    notify(`Error: ${error.message}`, { type: 'warning' });
    console.error('Failed to save school:', error);
  }
};

export const SchoolEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm
        save={(values) =>
          handleSubmit(
            values,
            notify,
            redirect,
            refresh,
            `${environment.apiUrl}/schoolsAdmin/${values.id}`,
            'PUT',
          )
        }
      >
        <TextInput source="name" defaultValue="null" />
        <TextInput source="description" defaultValue="null" />
        <TextInput source="address" defaultValue="null" />
        <TextInput source="phone" defaultValue="null" />
        <TextInput source="email" defaultValue="null" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="Icon" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="Image" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const SchoolCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm
        defaultValues={classDefaultValues}
        save={(values) =>
          handleSubmit(
            values,
            notify,
            redirect,
            refresh,
            `${environment.apiUrl}/schoolsAdmin`,
            'POST',
          )
        }
      >
        <TextInput source="name" />
        <TextInput source="description" defaultValue="null" />
        <TextInput source="address" defaultValue="null" />
        <TextInput source="phone" defaultValue="null" />
        <TextInput source="email" defaultValue="null" />
        <FileInput source="icon" defaultValue="nullable" accept="image/*">
          <FileField source="src" title="Icon" />
        </FileInput>
        <FileInput source="image" defaultValue="gffgfg" accept="image/*">
          <FileField source="src" title="Image" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
