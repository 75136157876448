// AuthProvider.js
import environment from './config/environment';
const apiUrl = environment.apiUrl;

const authProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${apiUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          // Si l'utilisateur n'existe pas ou que le mot de passe est incorrect,
          // on lève une erreur pour que la page de connexion reste affichée
          return response.json().then(err => {
            throw new Error(err.error || 'Erreur de connexion');
          });
        }
        return response.json();
      })
      .then(auth => {
        localStorage.setItem('auth', JSON.stringify(auth));
      });
  },

  logout: () => {
    const auth = localStorage.getItem('auth');
    
    if (!auth) {
        // Si l'utilisateur n'est pas authentifié, on peut simplement nettoyer le stockage local
        localStorage.removeItem('auth');
        return Promise.resolve();
    }

    const request = new Request(`${apiUrl}/logout`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(auth).access_token}`,
      }),
    });

    return fetch(request).then(() => {
      localStorage.removeItem('auth');
      return Promise.resolve();
    });
},


  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    return Promise.resolve();
  },
};

export default authProvider;
