import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  useRecordContext,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  Filter,
  Button,
} from "react-admin";
import PrintIcon from "@mui/icons-material/Print";

// Composant pour afficher les détails d'un Order
const OrderItemDetail = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <div style={{ padding: "10px" }}>
      <h3>Détails de l'Order #{record.id}</h3>
      <p>
        <strong>Class:</strong> {record.class?.name}
      </p>
      <p>
        <strong>Class Category:</strong> {record.class_category?.name}
      </p>
      <p>
        <strong>Coupon:</strong> {record.coupon?.first_name}
      </p>
      <p>
        <strong>Customer:</strong>{" "}
        {`${record.customer?.first_name} ${record.customer?.last_name}`}
      </p>
      <p>
        <strong>Customer phone:</strong> {record.customer?.phone}
      </p>
      <p>
        <strong>Customer address:</strong> {record.customer?.address}
      </p>
      <p>
        <strong>Customer email:</strong> {record.customer?.email}
      </p>
      <p>
        <strong>Track:</strong> {record.track?.name}
      </p>
      <p>
        <strong>Number of Students:</strong> {record.number_of_students}
      </p>
      <p>
        <strong>Delivery Cost:</strong> {record.delivery_cost}
      </p>
      <p>
        <strong>Total Amount:</strong> {record.total_amount}
      </p>
      <p>
        <strong>Payment Type:</strong> {record.payment_type}
      </p>

      {/* Liste des Order Items */}
      {record.order_items && record.order_items.length > 0 ? (
        <div>
          <h4>Order Items:</h4>
          <table
            style={{
              width: "100%",
              border: "1px solid #ddd",
              marginTop: "10px",
            }}
          >
            <thead>
              <tr>
                <th>Articles</th>
                <th>Quantité</th>
              </tr>
            </thead>
            <tbody>
              {record.order_items.map((item) => (
                <tr key={item.id}>
                  <td>{item.supply?.name}</td>
                  <td>{item.number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Aucun Order Item associé.</p>
      )}

      {/* Bouton d'impression */}
      <PrintOrderButton record={record} />
    </div>
  );
};

// Composant pour le bouton d'impression
const PrintOrderButton = ({ record }) => {
  const handlePrint = () => {
    if (record) {
      // Créer le contenu HTML pour l'impression
      const orderItemsContent = record.order_items
        ? record.order_items
            .map(
              (item) => `
              <tr>
                <td>${item.supply?.name || ""}</td>
                <td>${item.number || ""}</td>
              </tr>`
            )
            .join("")
        : "<tr><td colspan='2'>Aucun Order Item associé.</td></tr>";

      const printContent = `
        <html>
          <head>
            <title>Détails de l'Order</title>
            <style>
              body { font-family: Arial, sans-serif; }
              h3 { color: #333; }
              p { margin: 5px 0; }
              table { width: 100%; border-collapse: collapse; margin-top: 10px; }
              th, td { border: 1px solid #ddd; padding: 8px; }
              th { background-color: #f2f2f2; }
            </style>
          </head>
          <body>
            <h3>Détails de l'Order #${record.id}</h3>
            <p><strong>Class:</strong> ${record.class?.name || ""}</p>
            <p><strong>Class Category:</strong> ${record.class_category?.name || ""}</p>
            <p><strong>Coupon:</strong> ${record.coupon?.first_name || ""}</p>
            <p><strong>Customer:</strong> ${record.customer?.first_name || ""} ${record.customer?.last_name || ""}</p>
            <p><strong>Customer phone:</strong> ${record.customer?.phone || ""}</p>
            <p><strong>Customer address:</strong> ${record.customer?.address || ""}</p>
            <p><strong>Customer email:</strong> ${record.customer?.email || ""}</p>
            <p><strong>Track:</strong> ${record.track?.name || ""}</p>
            <p><strong>Number of Students:</strong> ${record.number_of_students || ""}</p>
            <p><strong>Delivery Cost:</strong> ${record.delivery_cost || ""}</p>
            <p><strong>Total Amount:</strong> ${record.total_amount || ""}</p>
            <p><strong>Payment Type:</strong> ${record.payment_type || ""}</p>
            <h4>Order Items:</h4>
            <table>
              <thead>
                <tr>
                  <th>Articles</th>
                  <th>Quantité</th>
                </tr>
              </thead>
              <tbody>
                ${orderItemsContent}
              </tbody>
            </table>
          </body>
        </html>
      `;
      const newWindow = window.open();
      newWindow.document.write(printContent);
      newWindow.document.close(); // Important pour finaliser le contenu avant l'impression
      newWindow.print();
      newWindow.close(); // Ferme la fenêtre après l'impression
    }
  };

  return (
    <Button onClick={handlePrint} label="Imprimer" style={{ marginTop: "10px" }}>
      <PrintIcon />
    </Button>
  );
};

// Filtre pour la liste des Orders
const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Numéro Client" source="customer_id" alwaysOn />
    <TextInput label="District" source="district" alwaysOn />
    <TextInput label="Date d'achat" source="date_d_achat" alwaysOn />
    <TextInput label="Region" source="region" alwaysOn />
    <TextInput label="Nom Client" source="customer_id" alwaysOn />
  </Filter>
);

export const OrderList = (props) => (
  <List filters={<OrderFilter />} {...props} debounce={500}>
    <Datagrid expand={<OrderItemDetail />}>
      <TextField source="id" />
      <ReferenceField label="Class" source="class_id" reference="classesAdmin">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="date_d_achat" />
      <TextField source="district" />
      <ReferenceField
        label="Class Category"
        source="class_category_id"
        reference="class_categoriesAdmin"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Coupon"
        source="coupon_id"
        reference="couponsAdmin"
      >
        <TextField source="first_name" />
      </ReferenceField>
      <ReferenceField
        label="Customer"
        source="customer_id"
        reference="customersAdmin"
      >
        <TextField source="first_name" />
      </ReferenceField>
      <ReferenceField label="Track" source="track_id" reference="tracksAdmin">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="School"
        source="school_id"
        reference="schoolsAdmin"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="region" />
      <TextField label="Number of Students" source="number_of_students" />
      <TextField label="Delivery Cost" source="delivery_cost" />
      <TextField label="Total Amount" source="total_amount" />
      <TextField label="Payment Type" source="payment_type" />
      <EditButton />
    </Datagrid>
  </List>
);

// Édition d'un Order
export const OrderEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <SelectInput
        source="delivery_status"
        label="Statut de livraison"
        choices={[
          { id: "pending", name: "En attente" },
          { id: "delivered", name: "Livré" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

// Création d'un Order
export const OrderCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="class_id" reference="classesAdmin" label="Class">
        <SelectInput optionText="name" />
      </ReferenceInput>
      {/* Ajoutez d'autres champs nécessaires ici */}
    </SimpleForm>
  </Create>
);
